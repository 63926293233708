// Animation fade-in
@keyframes fadein {
  0% {
    opacity: 0.2;
  }

  100% {
    opacity: 0.8;
  }
}

// Animation blur
@keyframes blur {
  0% {
    filter: blur(0px);
  }

  100% {
    filter: blur(4px);
  }
}

// Responsive embed video
.embed-responsive {
  height: 0;
  max-width: 100%;
  overflow: hidden;
  position: relative;
  padding-bottom: 56.25%;
  margin-top: 20px;

  iframe,
  object,
  embed {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
  }
}

// Codersrank
codersrank-activity {
  --bg-color-0: #f5f5f5;
  --bg-color-1: #{lighten(#660000, 60%)};
  --bg-color-2: #{lighten(#660000, 40%)};
  --bg-color-3: #{lighten(#660000, 20%)};
  --bg-color-4: #{lighten(#660000, 00%)};
}

body[data-theme="dark"] {
  codersrank-activity {
    --bg-color-4: #{lighten(#660000, 60%)};
    --bg-color-3: #{lighten(#660000, 40%)};
    --bg-color-2: #{lighten(#660000, 20%)};
    --bg-color-1: #{lighten(#660000, 00%)};
    --bg-color-0: #{ darken(#660000, 20%)};
  }
}
